/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// // http://ionicframework.com/docs/theming/
// // App Global Sass
// // --------------------------------------------------
// // Put style rules here that you want to apply globally. These
// // styles are for the entire app and not just one component.
// // Additionally, this file can be also used as an entry point
// // to import other Sass files to be included in the output CSS.
// //
// // Shared Sass variables, which can be used to adjust Ionic's
// // default Sass variables, belong in "theme/variables.scss".
// //
// // To declare rules for a specific mode, create a child rule
// // for the .md, .ios, or .wp mode classes. The mode class is
// // automatically applied to the <body> element in the app.
.split-pane-md.split-pane-visible > .split-pane-side {
  min-width: 250px;
  max-width: 15%;
  border-right: 1px solid #dedede;
  border-left: 0;
}

@media (max-width: 1220px) {
  .split-pane-md.split-pane-visible > .split-pane-side {
    min-width: 250px;
    max-width: 18%;
    border-right: 1px solid #dedede;
    border-left: 0;
  }
}

.stp-head-title {
  margin: 0px 0 0 0;
  font-weight: 500;
  color: white;
}

.menu-with-icon {
  .split-pane-md.split-pane-visible > .split-pane-side {
    min-width: 250px;
    max-width: 5%;
    border-right: 1px solid #dedede;
    border-left: 0;
  }

  max-width: 5% !important;

  .scroll-content {
    overflow: hidden !important;
  }
}

.icon-menu-title {
  padding-left: 3px;
  background-color: transparent;
}

.no-scroll {
  .scroll-content {
    overflow: hidden !important;
  }
}

.dash-text {
  margin: -18px 0 0 45px !important;
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
}

.header-box-border {
  border-radius: 0px;
  border: blue 1px;
  padding: 29px;
  width: 100%;
  height: 1rem;
  border-style: hidden;
  background: lightblue;
}

.header-account-img {
  float: right;
  margin-top: -14px;
  color: white;
}

.institute-name {
  margin-right: 65px;
  margin-top: 6px;
}

// .profile-img {
//   margin-left: 142px;
//   margin-top: -45px;
// }

.no-scroll .scroll-content {
  margin-top: 56px;
  background-color: #1ba9e0;
}

.menu-with-icon .scroll-content {
  background-color: #1ba9e0;
}

.alert-md .alert-wrapper {
  border-radius: 2px;
  max-width: 280px;
  margin: 185px 0 0 0;
  background-color: #fafafa;
  -webkit-box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
}

.tgyn-img {
  min-width: 203px;
  min-height: 76px;
  margin-left: 40px;
  margin-top: 230px;
}

.md .modal-wrapper {
  opacity: 1;
  margin: 43px 0 0 -3px;
  height: 70%;
  width: 50%;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  border-radius: 2px;
  overflow: hidden;
  -webkit-box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.button-hover:hover {
  color: #2699fb;
  font-family: Arial;
  font-weight: 500;
  background-color: #e2f1fd;
}

.edit-course-help-popup {
  --min-width: 25%;
  --width: 25%;
}

.editcourse-save-confirmation-alert {  

  .alert-button-group {
    justify-content: center;
    button {
      background-color: #1977cc;
      color: white;
      border-radius: 5px;      
      box-shadow: 4px 4px 10px -1px #888888;
    }
  }

  .alert-message{
    padding-bottom: 5px;
    color: black;
    font-size: 15px;
    font-weight: bold;
  }
}
